import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import { translateText } from '../config/translation'

const useStyles = makeStyles(theme => ({
  textBlock: {
    '& h2': {
      textAlign: 'center',
    }
  },
  mapWrapper: {
    paddingTop: 32,
    textAlign: 'center',
    overflow: 'hidden',
  },
}));

const Travel = ({ path, lang }) => {
  const classes = useStyles();
  return (
    <Layout pageName='Travel' activeRoute={ path } lang={ lang }>
      <SEO title="Travel" />
      <Container maxWidth="md" className={ classes.textBlock }>
        <h2>{ translateText("GETTING TO PHILADELPHIA", lang) }</h2>
        <p>{ translateText("There are many ways to get to Philadelphia, including driving, taking the Amtrak Northeast Regional Line, or flying into Philadelphia International Airport. Once in downtown Philadelphia, it is easy to get around by rideshare (Uber or Lyft) or on foot.", lang) }</p>
      
        <h2>{ translateText("STAYING IN PHILADELPHIA", lang) }</h2>
        <p>{ translateText("Kelly and Helio highly recommend Airbnb.com for lodging. There are many excellent, affordable listings available in Philadelphia. We recommend using our wedding map below to help you decide which neighborhood to stay in.", lang) }</p>
        <p>{ translateText("In addition to Airbnb, there are many hotels available right in the heart of the city that can be booked through the Google Hotels interface, or on travel sites such as Expedia, TripAdvisor, or Kayak.com.", lang) }</p>

        <h2>{ translateText("GETTING TO THE VENUE", lang) }</h2>
        <p>{ translateText("Although the venue is not in the heart of the city, it is a short car or train ride away.", lang) }</p>
        <p>{ translateText("The PSCA is conveniently located directly next to the Upsal Station on the Chestnut Hill West Regional Rail Line. If you choose to travel by train, there is a train that departs Suburban Station (16th and JFK) at 5:20pm and arrives at the venue at 5:45pm. To view the full Chestnut Hill West Line schedule, please check the SEPTA schedule ", lang) }<a href="https://septa.org/schedules/rail/pdf/chw.pdf">here</a>.</p>
        <p>{ translateText("If you choose to drive yourself, the PSCA has a large parking lot available to guests at no charge.", lang) }</p>
        <p>{ translateText("Rideshares (Uber or Lyft) are a popular and economical way to travel around the city and to the venue, and can be accessed by downloading the Uber or Lyft app.", lang) }</p>
        <p>{ translateText("To see more detailed information on the layout of the venue, train station, and parking lot, please visit our wedding map and select the Wedding Venue layer.", lang) }</p>
      
        <h2>{ translateText("LEAVING THE VENUE", lang) }</h2>
        <p>{ translateText("We will be providing transportation from the Philadelphia School of Circus Arts to our after-party at Everybody Hits (529 W Girard Ave) at the end of reception, around 11:15pm.", lang) }!</p>

        <h2>{ translateText("OUR WEDDING MAP", lang) }</h2>
        <p>{ translateText("The below map shows neighborhoods we recommend staying in, as well as our top picks for bars, restaurants, and things to do around Philadelphia. To open in Google Maps, please click here. Additionally, the Wedding Venue layer provides more detail on the venue and transportation hubs in Philadelphia", lang) }.</p>
        <p>{ translateText("Click on the items in the map below to learn more!", lang) }</p>
        <Box className={ classes.mapWrapper }>
          <iframe src="https://www.google.com/maps/d/embed?mid=1pEFyu9UzcIJvZtkDY-8HyrIz4n_Vt3Rq" width="640" height="480"></iframe>
        </Box>
      </Container>
    </Layout>
  )
}

export default Travel
